import type { NewsMediaPulseTracker } from "@schibsted/pulse-news-media";

const initTrackPageLeave = (tracker: NewsMediaPulseTracker) => {
  const { pageType } = document.documentElement.dataset;

  const disablePageLeaveTracking =
    document.querySelector("[data-track-disable-page-leave-tracking]") !== null;

  if (disablePageLeaveTracking) return;

  const contentElement =
    pageType === "Article"
      ? document.querySelector("article")
      : document.querySelector("main");

  tracker.addPageLeaveTracking(
    contentElement || document.body,
    document.body,
    undefined,
    {
      objectResizable: true,
    },
  );
};

export default initTrackPageLeave;
