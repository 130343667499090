import { type Article } from "@vgno/article";
import { TrackerStore } from "@vgno/pulse";
import { getUTMParams } from "@vgno/utils";

import { createIntersectionObserver, createTrackElement } from "./TrackElement";
import initTrackPageLeave from "./initTrackPageLeave";
import initTrackPageView from "./initTrackPageView";
import initUtmAppender from "./initUtmAppender";
import trackElementClick from "./trackElementClick";
import trackElementView from "./trackElementView";
import {
  getDataset,
  getExperiments,
  getMetrics,
  getOriginFromUtm,
} from "./utils";

const tracker = TrackerStore.get();

const utmTags = getUTMParams(window.location.toString(), true);
const features = JSON.parse(
  document.getElementById("features")?.textContent || "[]",
);
const articleData: Article["pulseData"] = JSON.parse(
  document.getElementById("article-pulse-data")?.textContent || "",
);

const isDev = import.meta.env.DEV;
const isFeature = window.location.host.includes("feature.sk8s.vg.no");

const init = async () => {
  const release = getDataset(
    "[data-release]",
    document.documentElement,
    "release",
  );

  // In local development you can see events in the pulse console
  // https://pulse.schibsted.io/tracking/debug/inspect-events
  tracker.pulseTracker.update({
    deployTag: release || "vgno-dev",
    deployStage: isFeature ? "pre" : isDev ? "dev" : "pro",
  });

  // This function call is here to add some global data to the tracker
  tracker.pulseTracker.update({
    experiments: await getExperiments(features),
    metrics: await getMetrics(),
    origin: getOriginFromUtm(utmTags),
    pageViewId: tracker.pulseTracker.getPageViewId(),
  });

  // We have a <track-element> custom element that we use to track views
  // of dynamically loaded elements.
  const TrackElement = createTrackElement((event) =>
    trackElementView(event, tracker),
  );

  if (!customElements.get("track-element")) {
    customElements.define("track-element", TrackElement);
  }

  // Here we initialize the tracking of the page view
  initTrackPageView(articleData, utmTags, tracker);

  // This function is used to append utm tags to links that are clicked
  initUtmAppender(trackElementClick, tracker);

  // Some events are available on dom ready, so we append the
  // view listeners to them directly instead if the above <track-element> wrapper
  const initTrackImpression = (): void => {
    const elements: Element[] = Array.from(
      document.querySelectorAll("[data-track-impression]:not(track-element)"),
    );
    const observer = createIntersectionObserver((event) =>
      trackElementView(event, tracker),
    );
    elements.forEach((element) => observer.observe(element));
  };

  // We need to wait for the dom to be ready before we can
  // start tracking impressions and page leave
  if (/complete|interactive/.test(document.readyState)) {
    initTrackImpression();
    initTrackPageLeave(tracker);
  } else {
    document.addEventListener("DOMContentLoaded", () => {
      initTrackImpression();
      initTrackPageLeave(tracker);
    });
  }
};

export default init;
