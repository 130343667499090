import { toSlug } from "@vgno/utils";

import { getDataset, getValidPageType, getValidTargetType } from "./utils";

import type {
  NewsMediaPulseTracker,
  ViewUIElementInputObject,
} from "@schibsted/pulse-news-media";

const trackElementView = (
  eventTarget: HTMLElement,
  tracker: NewsMediaPulseTracker,
): void => {
  const name = getDataset("[data-track-name]", eventTarget, "trackName");
  const id =
    getDataset("[data-track-id]", eventTarget, "trackId") || toSlug(name || "");

  if (!id) return;

  const target = getDataset("[data-track-target]", eventTarget, "trackTarget")!;

  const targetType = getDataset(
    "[data-track-target-type]",
    eventTarget,
    "trackTargetType",
  );

  const curateContext = getDataset(
    "[data-track-curate-context]",
    eventTarget,
    "trackCurateContext",
  );
  const positionInBundle = getDataset(
    "[data-track-bundle-position]",
    eventTarget,
    "trackBundlePosition",
    true,
  );
  // This is required, but no good default value, so letting schema fail if not present
  // TODO: Long term look into type validation for required fields in html data attributes
  const primaryPosition = getDataset(
    "[data-track-primary-position]",
    eventTarget,
    "trackPrimaryPosition",
    true,
  )!;
  const secondaryPosition = getDataset(
    "[data-track-secondary-position]",
    eventTarget,
    "trackSecondaryPosition",
    true,
  );
  const elementSize = getDataset(
    "[data-track-element-size]",
    eventTarget,
    "trackElementSize",
  );
  const imageUrl = getDataset(
    "[data-track-image-url]",
    eventTarget,
    "trackImageUrl",
  );
  const layoutName = getDataset(
    "[data-track-layout-name]",
    eventTarget,
    "trackLayoutName",
  );

  const elementType = getDataset(
    "[data-track-element-type]",
    eventTarget,
    "trackElementType",
  );

  const targetNewsroom = getDataset(
    "[data-track-target-newsroom]",
    eventTarget,
    "trackTargetNewsroom",
  );

  const placement: ViewUIElementInputObject["placement"] = {
    curateContext,
    positionInBundle,
    primaryPosition,
    secondaryPosition,
  };

  const layout: ViewUIElementInputObject["layout"] = {
    elementSize,
    imageUrl,
    name: layoutName,
  };

  tracker.trackViewUIElement({
    object: {
      id,
      elementType: elementType || "Element",
      name: name || "unknown",
      size: elementSize,
      placement,
      page: {
        id: document.documentElement.dataset.id || "unknown",
        type: getValidPageType(document.documentElement.dataset.pageType || ""),
        url: document.location.href,
      },
      target: {
        newsroom: targetNewsroom,
        url: target,
        type: getValidTargetType(targetType),
        id: id,
        name: name || "unknown",
      },
      ...(layout?.name && { layout }),
    },
  });
};

export default trackElementView;
