import { toSlug } from "@vgno/utils";

import { getDataset, getValidPageType, getValidTargetType } from "./utils";

import type {
  ClickUIElementInputObject,
  NewsMediaPulseTracker,
} from "@schibsted/pulse-news-media";

const trackElementClick = (
  eventTarget: HTMLElement,
  tracker: NewsMediaPulseTracker,
) => {
  const name = getDataset("[data-track-name]", eventTarget, "trackName");
  const id =
    getDataset("[data-track-id]", eventTarget, "trackId") || toSlug(name || "");

  if (!id) return;

  const target = getDataset("[data-track-target]", eventTarget, "trackTarget");

  const targetType = getDataset(
    "[data-track-target-type]",
    eventTarget,
    "trackTargetType",
  );

  const clickIntent = getDataset(
    "[data-track-click-intent]",
    eventTarget,
    "trackClickIntent",
  );

  const curateContext = getDataset(
    "[data-track-curate-context]",
    eventTarget,
    "trackCurateContext",
  );
  const positionInBundle = getDataset(
    "[data-track-bundle-position]",
    eventTarget,
    "trackBundlePosition",
    true,
  );
  const primaryPosition = getDataset(
    "[data-track-primary-position]",
    eventTarget,
    "trackPrimaryPosition",
    true,
  );
  const secondaryPosition = getDataset(
    "[data-track-secondary-position]",
    eventTarget,
    "trackSecondaryPosition",
    true,
  );
  const elementSize = getDataset(
    "[data-track-element-size]",
    eventTarget,
    "trackElementSize",
  );
  const imageUrl = getDataset(
    "[data-track-image-url]",
    eventTarget,
    "trackImageUrl",
  );
  const layoutName = getDataset(
    "[data-track-layout-name]",
    eventTarget,
    "trackLayoutName",
  );

  const elementType = getDataset(
    "[data-track-element-type]",
    eventTarget,
    "trackElementType",
  );

  const targetNewsroom = getDataset(
    "[data-track-target-newsroom]",
    eventTarget,
    "trackTargetNewsroom",
  );

  const layout: ClickUIElementInputObject["layout"] = {
    elementSize,
    imageUrl,
    name: layoutName,
  };

  tracker.trackClickUIElement({
    target: {
      newsroom: targetNewsroom,
      url: target || document.location.href,
      type: getValidTargetType(targetType),
      id: id,
      name: name || "unknown",
    },
    object: {
      intent: clickIntent,
      elementType: elementType || "Element",
      size: elementSize,
      id,
      name: name || "unknown",
      page: {
        id: document.documentElement.dataset.id || "unknown",
        type: getValidPageType(document.documentElement.dataset.pageType || ""),
        url: document.location.href,
      },
      ...(primaryPosition && {
        placement: {
          curateContext,
          positionInBundle,
          primaryPosition,
          secondaryPosition,
        },
      }),
      ...(layout?.name && { layout }),
    },
  });
};

export default trackElementClick;
