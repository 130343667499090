type TrackImpression = (element: HTMLElement) => void;

export const createIntersectionObserver = (trackImpression: TrackImpression) =>
  new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          trackImpression(entry.target as HTMLElement);
          observer.unobserve(entry.target);
        }
      });
    },
    {
      rootMargin: "0px",
      threshold: 0.5,
    },
  );

const getStyle = (elem: HTMLElement, prop: string) =>
  elem.computedStyleMap
    ? elem.computedStyleMap().get(prop)?.toString()
    : getComputedStyle(elem).getPropertyValue(prop);

export function createTrackElement(
  trackElementView: TrackImpression,
): typeof HTMLElement {
  const observer = createIntersectionObserver(trackElementView);

  class TrackElement extends HTMLElement {
    constructor() {
      super();
    }

    connectedCallback() {
      observer.observe(this.trackElement());
    }

    disconnectedCallback() {
      observer.unobserve(this.trackElement());
    }

    trackElement(elem: Element | null = this.firstElementChild): HTMLElement {
      if (elem === null || !(elem instanceof HTMLElement)) {
        throw new Error("TrackElement must have a child element");
      }

      if (elem.computedStyleMap?.().get("display")?.toString() === "contents") {
        return this.trackElement(elem.firstElementChild);
      }

      if (getStyle(elem, "display") === "contents") {
        return this.trackElement(elem.firstElementChild);
      }

      return elem;
    }
  }

  return TrackElement;
}
