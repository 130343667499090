import { type Article } from "@vgno/article";
import {
  cleanUTMParams,
  whenPrerenderingActivated,
  type getUTMParams,
} from "@vgno/utils";

import type { NewsMediaPulseTracker } from "@schibsted/pulse-news-media";

const trackPageView = (
  articleData: Article["pulseData"],
  utmTags: ReturnType<typeof getUTMParams>,
  tracker: NewsMediaPulseTracker,
) => {
  const { id, name, pageType } = document.documentElement.dataset;

  const viewFunction = async () => {
    switch (pageType) {
      case "Article":
        return tracker.trackViewArticle({
          object: {
            accessLevel: articleData.accessLevel,
            accessLevelName: articleData.accessLevelName,
            articleId: id ?? "unknown",
            authors: articleData.authors,
            category: articleData.category,
            contentId: articleData.contentId,
            group: articleData.group,
            name: name ?? "",
            page: {
              id: id ?? "",
              type: "Article",
              url: articleData.pageUrl,
            },
            "spt:custom": articleData["spt:custom"],
            imageUrl: articleData.imageUrl,
            tags: articleData.tags,
            updated: articleData.updated,
            wordCount: articleData.wordCount,
          },
        });

      case "SalesPoster":
        // SalePoster and trackViewLoginPoster tracking happens in the SalePoster component itself
        break;

      case "Frontpage":
        return tracker.trackViewFrontpage({
          object: {
            id: id ?? "unknown",
            name: name ?? "unknown",
            page: {
              id: id ?? "",
              type: "Frontpage",
              url: window.location.toString(),
            },
          },
        });

      case "Listing":
        return tracker.trackViewListing({
          object: {
            id: id ?? "unknown",
            name: name ?? "unknown",
            page: {
              id: id ?? "",
              type: "Listing",
              url: window.location.toString(),
            },
          },
        });

      default:
        return tracker.trackViewPage({
          object: { name: name ?? "unknown", pageId: id ?? "unknown" },
        });
    }
  };

  whenPrerenderingActivated()
    .then(() =>
      viewFunction()
        .finally(() => {
          const newParams = cleanUTMParams(window.location.toString());
          if (newParams !== window.location.search) {
            try {
              window.history.replaceState(
                { ...window.history.state, utmTags },
                "",
                location.pathname + (newParams ? `?${newParams}` : ""),
              );
            } catch {
              console.warn(
                "history.replaceState will fail on dev, where we use base tag",
              );
            }
          }
        })
        .catch((error) => {
          console.error("Failed to track page view", error);
        }),
    )
    .catch((error) => console.error("Failed to activate prerendering", error));

  window.addEventListener("pageshow", (event) => {
    if (event.persisted) viewFunction();
  });
};

export default trackPageView;
